import React from "react";
import Company from "../Components/Common/HeadTitle";
import Tabs from "../Components/IntroComp/IntroTabs";

// 회사소개 화면배치 ! 병합완료
function IntroComp() {
  return (
    <div>
      <Company />
      <Tabs />
    </div>
  );
}

export default IntroComp;
